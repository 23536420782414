<template>
  <section>
    <div class="container-fluid">
      <div class="container">
        <h1>Nasze sklepy</h1>
        <b-table striped hover :items="shops" sort-by="Miejscowość"></b-table>
      </div>
    </div>
  </section>
</template>
<script>
import ShopService from '../_services/shop.service';

const shopService = new ShopService();

export default {
  name: 'Shops',
  data() {
    return {
      shops: [],
    };
  },
  beforeCreate() {
    this.$store.state.loading = true;
  },
  async created() {
    await shopService.getShopList('all')
      .then((shops) => {
        this.shops = shops.map((shop) => ({
          'Nazwa sklepu': `${shop.name}`,
          'Miejscowość': `${shop.city}`,
          'Ulica': `${shop.street}`,
        }));
        this.$store.state.loading = false;
      });
  },
};
</script>
